import request from "@/util/requset";
import { getToken } from "@/util/auth";
// 联系我们和意见反馈的新增
export function user_refer_record_add(data: user_ts_api.feedback_body_ts_api) {
    return request({ url: '/api/user_refer_record/add', method: 'POST', data: data })
}

// 联系我们和意见反馈的列表
export function user_refer_record_list(data: user_ts_api.feedback_contact_list_body_ts_api) {
    return request({ url: '/api/user_refer_record/list', method: 'POST', data: data, headers: { Authorization: getToken() } })
}

//联系我们和意见反馈的删除
export function user_refer_record_del(data: { id: number }) {
    return request({ url: '/api/user_refer_record/del', method: 'POST', data: data, headers: { Authorization: getToken() } })
}

// 用户登录
export function sycUserlogin(data: user_ts_api.login_body_ts_api) {
    return request({ url: '/api/sysUser/login', method: 'POST', data: data })
}

// 记录访问
export function pagesload() {
    return request({ url: '/api/pages/load', method: 'POST', data: {} })
}

// 记录访问统计
export function pagesviewsCount() {
    return request({ url: '/api/pages/viewsCount', method: 'POST', data: {}, headers: { Authorization: getToken() } })
}


/**新增饰面设计分类 */
export function add_veneer_cate(data: { name: string }) {
    return request({ url: '/api/veneer_cate/add', method: 'POST', data: data, headers: { Authorization: getToken() } })
}

/**删除饰面设计分类  */
export function del_veneer_cate(data: { id: number }) {
    return request({ url: '/api/veneer_cate/del', method: 'POST', data: data, headers: { Authorization: getToken() } })
}

/*饰面设计分类详情 */
export function detail_veneer_cate(data: { id: string }) {
    return request({ url: '/api/veneer_cate/detail', method: 'POST', data: data, headers: { Authorization: getToken() } })
}

/**编辑饰面设计 */
export function edit_veneer_cate(data: { id: number ,name: string}) {
    return request({ url: '/api/veneer_cate/edit', method: 'POST', data: data, headers: { Authorization: getToken() } })
}


// 饰面设计分类
export function veneer_cate_list() {
    return request({ url: '/api/veneer_cate/list', method: 'POST', data: {}, headers: { Authorization: getToken() } })
}


