<template>
  <div class="logo-body">
    <template v-if="isCollapse">
      <img src="/imge/logo-1.png" />
    </template>
    <template v-else>
      <img src="/imge/logo.png" />
    </template>
  </div>
  <el-menu
    :default-active="current.path"
    active-text-color="#000"
    background-color="#fff"
    text-color="#000"
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
    @open="handleOpen"
    @close="handleClose"
    router
  >
    <el-menu-item :index="item.path" v-for="item in menulist">
      <el-icon>
        <component :is="item.icon"></component>
      </el-icon>
      <template #title>
        <span style="font-weight: 600">{{ item.title }}</span>
      </template>
    </el-menu-item>
  </el-menu>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const current = computed(() => {
  return router.currentRoute.value;
});
import { useStore } from "vuex";
const store = useStore();

const isCollapse = computed(() => {
  return store.state.layout.isCollapse;
});

const menulist = [
  { path: "/admin-home-page", title: "首页", icon: "Menu" },
  { path: "/admin-casus", title: "案例展示", icon: "List" },
  { path: "/admin-venners", title: "饰面设计", icon: "Ticket" },
  { path: "/admin-contact-us", title: "联系我们", icon: "Promotion" },
  { path: "/admin-feedback", title: "意见反馈", icon: "List" },
  { path: "/admin-stores", title: "授权门店", icon: "TrendCharts" },
];

const handleOpen = (key: string, keyPath: string[]) => {
  // console.log(key, keyPath);
};
const handleClose = (key: string, keyPath: string[]) => {
  // console.log(key, keyPath);
};
</script>

<style scoped lang="scss">
@import "../../style/app.scss";
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-menu {
  border-right: 0;
  --el-menu-item-height: 50px;
  :deep .is-active {
    background: rgba(212, 212, 212, 0.2);
    border-right: 3px solid #001;
    padding: 0px;
  }
}

.logo-body {
  background-color: $my_main__color_1;
  height: 60px;
  position: relative;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
  }
}
.menu-list {
  display: flex;
}
</style>
