<template>
  <div class="icon">
    <el-icon v-if="isCollapse" @click="change">
      <Fold />
    </el-icon>
    <el-icon v-else @click="change">
      <Expand />
    </el-icon>
  </div>
  <div class="right">
    <img src="/imge/logo-1.png" class="user-picture" />
    <el-icon @click="exit"><SwitchButton /></el-icon>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
import { useStore } from "vuex";
const store = useStore();

const isCollapse = computed(() => {
  return store.state.layout.isCollapse;
});

const change = function () {
  store.commit("layout/set_state_val", {
    key: "isCollapse",
    data: !store.state.layout.isCollapse,
  });
  // set_state_val
};

const exit = function () {
  router.push("/admin-login");
};
</script>

<style lang="scss" scoped>
.title {
  width: 100px;
  margin: -22px 0px 0 28px;
}

.icon {
  margin-top: 15px;
}

.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}

.right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;

  .el-icon--right {
    display: none;
  }
}
.user-picture {
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 999px;
  margin-right: 30px;
}
</style>
