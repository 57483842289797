import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

import Layout from '@/components/layout/index.vue';
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "reception-home-page",
    meta: {
      logon: false,
      title: "首页",
    },
    component: () =>
      import("@/views/reception/home-page.vue"),
  },
  {
    path: "/test",
    name: "test",
    meta: {
      logon: false,
      title: "test",
    },
    component: () =>
      import("@/views/reception/test.vue"),
  },
  {
    path: "/reception-decorative-design",
    name: "reception-decorative-design",
    meta: {
      logon: false,
      title: "饰面设计",
    },
    component: () =>
      import("@/views/reception/decorative-design.vue"),
  },
  {
    path: "/reception-case-presentation",
    name: "reception-case-presentation",
    meta: {
      logon: false,
      title: "案例展示",
    },
    component: () =>
      import("@/views/reception/case-presentation.vue"),
  },
  {
    path: "/reception-licensing",
    name: "reception-licensing",
    meta: {
      logon: false,
      title: "品牌授权",
    },
    component: () =>
      import("@/views/reception/licensing.vue"),
  },
  {
    path: "/reception-contact-us",
    name: "reception-contact-us",
    meta: {
      logon: false,
      title: "联系我们",
    },
    component: () =>
      import("@/views/reception/contact-us.vue"),
  },
  {
    path: "/reception-feedback",
    name: "reception-feedback",
    meta: {
      logon: false,
      title: "联系我们",
    },
    component: () =>
      import("@/views/reception/feedback.vue"),
  }, {
    path: "/admin-login",
    name: "admin-login",
    meta: {
      logon: false,
      title: "登录",
    },
    component: () => import("@/views/admin/login.vue"),
  }, {
    path: "/admin-layout",
    name: "admin-layout",
    component: Layout,
    redirect: "/admin-home-page",
    children: [{
      path: "/admin-home-page",
      name: "admin-home-page",
      component: () => import("@/views/admin/home-page.vue"),
    }, {
      path: "/admin-contact-us",
      name: "admin-contact-us",
      component: () => import("@/views/admin/contact-us.vue"),
    }, {
      path: "/admin-feedback",
      name: "admin-feedback",
      component: () => import("@/views/admin/feedback.vue"),
    }, {
      path: "/admin-casus",
      name: "admin-casus",
      component: () => import("@/views/admin/casus.vue"),
    }, {
      path: "/admin-venners",
      name: "admin-venners",
      component: () => import("@/views/admin/venners.vue"),
    }, {
      path: "/admin-stores",
      name: "admin-stores",
      component: () => import("@/views/admin/stores.vue"),
    }]
  }

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
