import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './style/app.scss';
import "@/style/iconfont/iconfont.css";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { pagesload } from "@/api/user";

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}


/**添加访问记录 */
pagesload();

app.use(ElementPlus);
app.use(store);
app.use(router);
app.mount("#app");
