<template>
  <router-view />
</template>

<script setup lang="ts"></script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
* {
  margin: 0px;
  padding: 0px;
}
img {
  display: block;
}
.veneer-cate-ElMessageBox {
  .el-message-box__title {
    color: #000;
    font-weight: bold;
  }
  .el-message-box__container {
    color: #000;
    font-size: 14px;
  }
  .el-button--primary {
    --el-button-bg-color: #000;
    --el-button-text-color: var(--el-color-white);
    --el-button-border-color: #000;
    --el-button-hover-bg-color: rgb(77, 77, 77);
    --el-button-hover-text-color: var(--el-color-white);
    --el-button-hover-border-color: rgb(77, 77, 77);
    --el-button-active-bg-color: rgb(4, 4, 4);
    --el-button-active-border-color: rgb(4, 4, 4);
  }
  .el-input__wrapper{
    --el-component-size: 50px;
  --el-border-radius-base: 8px;
  --el-text-color-regular: #000;
  .el-select {
    width: 100% !important;
  }

  .el-select__wrapper {
    min-height: 50px !important;
  }

  --el-input-hover-border-color: #000;
  --el-input-focus-border-color: #000;
  --el-input-border-color: #000;
  --el-text-color-placeholder: #000;
  --el-input-icon-color: #000;
  --el-component-size: 50px;
  --el-border-radius-base: 8px;
  }
}
</style>
