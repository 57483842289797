
//创建存储
export default {
    namespaced: true,
    // vuex 数据
    state: {
        isCollapse: false,
    },
    //vuex计算属性
    getters: {},
    //同步方法
    mutations: {
        set_state_val(state: any, data: { key: string; data: any }) {
            state[data.key] = data.data;
        },
    },

};