
import axios from "axios";
import { ElNotification } from "element-plus";
import { removeToken } from "@/util/auth";
import { domainName } from "@/util/public";


declare module "axios" {
  interface AxiosResponse<T = any> {
    errorinfo: null;
    msg: string;
    code: number;
  }
  export function create(config?: AxiosRequestConfig): AxiosInstance;
}

const getproxyaddress = (): string => {
  return domainName;
  // return "https://xr-api.11dom.com";
};

const service = axios.create({
  // baseURL: getproxyaddress(),
  timeout: 1000 * 120,
});

const failureprompt = (message: string) => {
  ElNotification({
    title: "ERROR 😥",
    message: message,
    type: "error",
    duration: 2000,
  });
}

let errorprompt: api_return_ts_api = {
  data: {},
  msg: "请求失败",
  code: 500,
};

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject({ ...errorprompt, msg: error });
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data as api_return_ts_api;
    if (res.code === 401) {
      // 删除登录令牌
      removeToken();
      failureprompt(res.msg);
      return Promise.resolve({ ...errorprompt, msg: res.msg });
    }
    return res;
  },
  (error) => {
    failureprompt(error.message)
    return Promise.reject({ ...errorprompt, msg: error });
  }
);

export default service;

// import axios from "axios";

// declare module "axios" {
//   interface AxiosResponse<T = any> {
//     errorinfo: null;
//     msg: string;
//     statusCode: number;
//   }
//   export function create(config?: AxiosRequestConfig): AxiosInstance;
// }

// import { ElMessage } from "element-plus";


// const errorHandler = (error) => {
//   let { data } = error || {};

//   if (data?.msg || error.msg) {
//     ElMessage.error(data?.msg || error.msg);
//   }
//   if (error.status === 401 || data.code === 401) {
//     // router.push({
//     //   path: "/login",
//     //   replace: true,
//     // });
//   }
// };

// // 请求拦截器
// instance.interceptors.request.use(
//   (config) => {
//     config.url = transferURL(config.url);
//     return config;
//   },
//   (error) => {
//     let errmsg = typeof error === "object" ? "请求错误" : '';
//     ElMessage.error(errmsg);
//     return Promise.error(error);
//   }
// );

// // 响应拦截器
// instance.interceptors.response.use(
//   (response) => {
//     if (response?.data?.code === 1) {
//       return Promise.resolve(response.data);
//     }
//     errorHandler(response);
//     // loadingUtil.close();
//     return Promise.reject(response);
//   },
//   (error) => {
//     errorHandler(error?.response || {});
//     return Promise.reject(error.response);
//   }
// );

// const transferURL = (url) => {
//   return url;
// };

// export default instance;
